import { SectionParser, useGetLocalesQuery } from '@front/master';
import { Section } from '@shared/master-types';
import React from 'react';

import {
  TCustomCardRow,
  CustomCardRow,
  TagTableRow,
  TTagTableRow,
} from '../../../rows';

export type TSectionsListProps = {
  sections: Section[];
  locale: string;
  draft: boolean;
};

export const SectionsList: React.FC<TSectionsListProps> = props => {
  const { sections, draft, locale } = props;

  const { data: localesObject } = useGetLocalesQuery({ locale, draft });

  const locales = localesObject?.docs;

  const customSectionRowRenderer = (
    row: TCustomCardRow | TTagTableRow,
  ): React.ReactNode => {
    if (row.blockType === 'custom-cards') {
      return <CustomCardRow key='custom-cards' row={row} />;
    }

    if (row.blockType === 'tag-table') {
      return <TagTableRow key='tag-table' row={row} />;
    }

    return undefined;
  };

  return (
    <>
      {sections?.map(section => (
        <SectionParser
          key={section.id}
          section={section}
          locales={locales}
          customSectionRowRenderer={customSectionRowRenderer}
        />
      ))}
    </>
  );
};
