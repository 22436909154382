import Image from 'next/image';
import React from 'react';

export const variantOfHero = {
  home: {
    listImg: [
      <Image
        key='home-right-mobile'
        src='/images/home/hero-mobile.png'
        alt='Home right mobile'
        width={1024}
        height={140}
        className='absolute left-1/2 top-0 max-w-none -translate-x-1/2 xl:hidden'
        priority
      />,
      <Image
        key='home-desktop-left'
        src='/images/home/hero-desktop-left.png'
        alt='Home desktop left'
        width={332}
        height={479}
        className='absolute top-0 hidden max-[930px]:right-[332px] min-[930px]:left-0 xl:block'
        priority
      />,
      <Image
        key='home-desktop-right'
        src='/images/home/hero-desktop-right.png'
        alt='Home destop right'
        width={408}
        height={514}
        className='absolute top-0 hidden max-[930px]:left-[408px] min-[930px]:right-0 xl:block'
        priority
      />,
    ],
  },
};
