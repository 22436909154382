import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { ECardWrapperBackground } from './CardWrapper.constants';

export type TCustomCardProps = {
  /**
   * Background.
   */
  background?: ECardWrapperBackground;
  /**
   * Children.
   */
  children: ReactNode;
  /**
   * Custom class name.
   */
  className?: string;
};

export const CustomCard: React.FC<TCustomCardProps> = ({
  className,
  background = ECardWrapperBackground.Light,
  children,
}) => {
  let cardBackground = ' bg-surface-100';
  if (background === ECardWrapperBackground.Dark) {
    cardBackground = ' bg-control-1000';
  } else if (background === ECardWrapperBackground.White) {
    cardBackground = ' bg-surface-50';
  }

  return (
    <div className={clsx('h-full  rounded-3xl', cardBackground, className)}>
      {children}
    </div>
  );
};
