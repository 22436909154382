import { ETagSize, ETagTheme, ETagVariant, Tag } from '@front/shared/ds';
import React from 'react';

export type TTagTableRow = {
  item: {
    category: string;
    list: string; // each list item is separated by a new line
  }[];
  id?: string;
  headerText?: string;
  blockName?: string;
  blockType: 'tag-table';
};

type TTagTableRowProps = {
  row: TTagTableRow;
};

export const TagTableRow: React.FC<TTagTableRowProps> = ({ row }) => {
  return (
    <section className={'grid grid-cols-1 gap-6 lg:grid-cols-none'}>
      <div className='scrollbar-hidden flex w-full overflow-auto scroll-smooth px-4'>
        <div className='2xl:w-full'>
          {!!row.headerText && (
            <div className='mb-4 rounded-2xl bg-surface-1000 p-6'>
              <h2 className='text-center text-2xl font-semibold leading-relaxed text-white'>
                {row.headerText}
              </h2>
            </div>
          )}
          <div className='flex items-start justify-center gap-4 self-stretch'>
            {row.item.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex w-full min-w-[14rem] flex-col items-start gap-4 self-stretch rounded-2xl bg-surface-100 p-6 2xl:min-w-0'
                >
                  <Tag
                    variant={ETagVariant.Contained}
                    theme={ETagTheme.PrimaryOnColour}
                    size={ETagSize.Medium}
                  >
                    {item.category}
                  </Tag>

                  {item.list.split('\n').map((listItem, idx) => {
                    return (
                      <p
                        key={listItem + idx}
                        className='text-sm font-normal not-italic leading-relaxed text-interface-800'
                      >
                        {listItem}
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
