import { useActions, useGetLocalesQuery } from '@front/master';
import { addJurisdictionSupport, getGaId } from '@front/shared';
import { Container, EContainerVariant } from '@front/shared/ds';
import { Hero as THero } from '@shared/master-types';
import React from 'react';

import { HeroBackground, variantOfHero, HeroSection } from '../../../heros';

type THeroSectionProps = {
  hero: THero;
  locale: string;
  draft: boolean;
};

export const Hero: React.FC<THeroSectionProps> = ({ hero, locale, draft }) => {
  const { data: localesObject } = useGetLocalesQuery({ locale, draft });

  const locales = localesObject?.docs;

  const heroActions = addJurisdictionSupport({
    jurisdictionList: [],
    localeList: locales,
    actionList: useActions(hero?.actions),
  });

  const fixedAction = useActions(hero?.fixedElement?.action);
  const images = hero.images ? variantOfHero[hero.images] : { listImg: [] };

  return (
    <div className='overflow-hidden 2xl:pb-16'>
      <Container
        variant={EContainerVariant.Lead}
        dataGaId={getGaId(hero.images, 'hero')}
      >
        <HeroBackground {...images}>
          <HeroSection
            title={hero.title}
            subtitle={hero.subtitle}
            actions={heroActions}
            fixedAction={fixedAction}
          />
        </HeroBackground>
      </Container>
    </div>
  );
};
