import { Button, EButtonSize, FixedElement, TAction } from '@front/shared/ds';
import { getGaId } from '@front/shared/utils';
import Link from 'next/link';
import React from 'react';

export type THeroSectionProps = {
  actions: TAction[];
  fixedAction?: TAction[];
  title: string;
  subtitle?: string;
};

export const HeroSection: React.FC<THeroSectionProps> = props => {
  const { actions = [], title, subtitle, fixedAction } = props;

  return (
    <section>
      {fixedAction?.map(action => <FixedElement key={title} action={action} />)}
      <div className='grid items-center rounded-3xl bg-surface-1000 p-4 pt-[12.25rem] xl:h-[31.25rem] xl:p-8'>
        <div className='z-10 mt-auto grid gap-8 xl:mt-0 xl:auto-rows-max xl:gap-12'>
          <div className='mx-auto flex flex-col-reverse gap-4 text-center xl:w-7/12 2xl:max-w-[51rem]'>
            <h1 className='text-6xl font-semibold leading-relaxed text-interface-50 xl:text-9xl xl:leading-tight'>
              {title}
            </h1>
            {subtitle && (
              <h2 className='text-lg font-medium text-brand-500'>{subtitle}</h2>
            )}
          </div>
          <div className='mx-auto flex w-full flex-col justify-center gap-4 empty:hidden xl:flex-row 2xl:max-w-[50rem]'>
            {actions.map((action, index) => (
              <div className='grid 2xl:grid' key={index}>
                {action.type === 'button' && (
                  <Button
                    {...action.props}
                    size={EButtonSize.Large}
                    data-ga-id={getGaId(action.dataGaId || action.text, 'hero')}
                  />
                )}
                {action.type === 'link' && (
                  <Link
                    className='flex items-center justify-center gap-2.5 rounded-2xl bg-brand-500 px-10 py-5 text-center text-base font-medium leading-tight text-brand-50/90 transition last:border last:border-brand-500 last:bg-transparent last:text-brand-500 hover:bg-brand-550 hover:text-brand-50'
                    {...action.props}
                    data-ga-id={getGaId(action.dataGaId || action.text, 'hero')}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
