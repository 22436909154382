export const enum ECardWrapperLayout {
  DefaultCard = 'DefaultCard',
  DefaultCardWithBg = 'DefaultCardWithBg',
  BannerCard = 'BannerCard',
  BannerCardWithBg = 'BannerCardWithBg',
  HorizontalCard = 'HorizontalCard',
  HorizontalCardWithBg = 'HorizontalCardWithBg',
  BrandCard = 'BrandCard',
  CustomCard = 'CustomCard',
}

export const enum ECardWrapperBackground {
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
}
