import { Container, toImageProps } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import Image from 'next/image';
import React from 'react';

import { ECardWrapperBackground } from '../../atoms/CustomCard/CardWrapper.constants';
import { CustomCard } from '../../atoms/CustomCard/CustomCard';

export type TCustomCardRow = {
  cards: {
    id: string;
    images: {
      image: string | Media;
      id: string;
    }[];
  }[];
  id: string;
  blockName?: string;
  blockType: 'custom-cards';
};

type TCustomCardRowProps = {
  row: TCustomCardRow;
};

export const CustomCardRow: React.FC<TCustomCardRowProps> = ({
  row: { cards },
}) => {
  return (
    <section className='scrollbar-hidden mb-4 flex overflow-auto scroll-smooth last:mb-0'>
      <Container>
        <div className='-mx-2 flex flex-nowrap items-stretch gap-y-4'>
          {cards.map((card, i) => (
            <div
              key={i}
              className='mx-2 min-w-[95%] shrink-0 md:shrink 2xl:min-w-0'
            >
              <CustomCard
                background={ECardWrapperBackground.Light}
                className='flex p-8'
              >
                {card.images.map(image => {
                  const imageProps = toImageProps(image.image);
                  if (!imageProps) return null;

                  return (
                    <div className='flex w-full items-stretch' key={image.id}>
                      <Image {...imageProps} alt={imageProps.alt} />
                    </div>
                  );
                })}
              </CustomCard>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
